import './App.css';
import Header from './MyComponent/Header';
import Footer from './MyComponent/Footer';
import Dashboard from './MyComponent/Dashboard';
import Sidebar from './MyComponent/Sidebar';
import AddProperty from './MyComponent/AddProperty';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
    <div className="wrapper">
    {/* <h3>React Home</h3> */}
      {/* <Header/>
      <Dashboard/>
      <Footer/> */}
      
 
      <Header/>
      <Sidebar/>

      

      <BrowserRouter>
      <Routes> 
          <Route path="" element={<Dashboard />} />
          <Route path="addproperty" element={<AddProperty />} />
      </Routes>
      </BrowserRouter>
      <Footer/>
      {/* <Footer/> */}

      <aside className="control-sidebar control-sidebar-dark">

      </aside>

      </div>  

      </>

  );
}

export default App;
